import axios from 'axios'


const localUrl = process.env.REACT_APP_LOCAL_URL;
const productionUrl = "/"


// react 

const API = axios.create({ baseURL: process.env.NODE_ENV === 'production' ? productionUrl : localUrl })


export const setToken =(token) => {
    API.interceptors.request.use(async (req) => {
        //   if (localStorage.getItem('profile')) {
        //     req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
        //   }
        //const token = setToken
    
        const config = {
            'Content-type': 'application/json',
            'x-auth-token': token,
        }
    
        req.headers = config
    
        return req
    })
    
}

//Get informations about the app
export const getInfos = () => API.get('/api/infos');

//Auth calls
export const registerUser = () => API.post(`/api/auth`)
export const authUser = (data) => API.post(`/api/auth`, data)
export const updatePassword = (data) => API.post(`/api/auth/updatepass`, data)
export const updateUserData = (data) => API.post(`/api/users/update`, data)


//Vehicles calls
export const getAllVehicles = () => API.get(`/api/vehicles`)
export const addVehicle = (data) => API.post(`/api/vehicles`, data)
export const deleteVehicle = (id) => API.delete(`/api/vehicles/${id}`)

//Drivers calls
export const getAllDrivers = () => API.get(`/api/drivers`)
export const addDriver = (data) => API.post(`/api/drivers`, data)
export const deleteDriver = (id) => API.delete(`/api/drivers/${id}`)

//Assistants calls
export const getAllAssistants = () => API.get(`/api/assistants`)
export const addAssistant = (data) => API.post(`/api/assistants`, data)
export const deleteAssistant = (id) => API.delete(`/api/assistants/${id}`)

//Waste categories calls
export const getAllWasteCategories = () => API.get(`/api/wastecategories`)
export const findWasteCategory = (eka) =>
    API.post(`/api/wastecategories/find`, eka)
export const addWasteCategory = (data) => API.post(`/api/wastecategories`, data)
export const deleteWasteCategory = (id) =>
    API.delete(`/api/wastecategories/${id}`)

//ServiceArea calls
export const getAllServiceAreas = () => API.get(`/api/serviceareas`)
export const addServiceArea = (data) => API.post(`/api/serviceareas`, data)
export const deleteServiceArea = (id) => API.delete(`/api/serviceareas/${id}`)   

//Area calls
//ServiceArea calls
export const getAllAreas = () => API.get(`/api/areas`)
export const addArea = (data) => API.post(`/api/areas`, data)
export const deleteArea = (id) => API.delete(`/api/areas/${id}`)    


//ServiceArea calls
export const getAllMunicipalities = () => API.get(`/api/municipalities`)
export const addlMunicipalitiy = (data) => API.post(`/api/municipalities`, data)
export const deleteMunicipality= (id) => API.delete(`/api/municipalities/${id}`)    


//Waste calls
export const getAllWasteData = () => API.get(`/api/wastedatas`)
export const addWasteData = (data) => API.post(`/api/wastedatas`, data)
export const deleteWasteData= (id) => API.delete(`/api/wastedatas/${id}`)    

// export const fetchPosts = (page) => API.get(`/posts?page=${page}`);
// export const fetchPostsByCreator = (name) => API.get(`/posts/creator?name=${name}`);
// export const fetchPostsBySearch = (searchQuery) => API.get(`/posts/search?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`);
// export const createPost = (newPost) => API.post('/posts', newPost);
// export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
// export const comment = (value, id) => API.post(`/posts/${id}/commentPost`, { value });
// export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);
// export const deletePost = (id) => API.delete(`/posts/${id}`);

// export const signIn = (formData) => API.post('/user/signin', formData);
// export const signUp = (formData) => API.post('/user/signup', formData);
export const fetchTodo = (id) => API.get(`/todos/${id}`)
