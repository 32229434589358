import Mock from '../mock'
import shortId from 'shortid'

const NotificationDB = {
    list: [
        {
            id: shortId.generate(),
            heading: 'Μήνυμα',
            icon: {
                name: 'chat',
                color: 'primary',
            },
            timestamp: Date.now(),
            title: 'Προσθήκη πεδίου',
            subtitle: 'Προστέθηκε ο Δήμου Περιστερίου',
            path: 'chat',
        },
        {
            id: shortId.generate(),
            heading: 'Ειδοποίηση',
            icon: {
                name: 'notifications',
                color: 'error',
            },
            timestamp: Date.now(),
            title: 'Διαγραφή Καρτέλας',
            subtitle: 'Η καρτέλα #ερ12 διαγράφηκε απο την βάση δεδομένων',
            path: 'page-layouts/user-profile',
        },
        {
            id: shortId.generate(),
            heading: 'Μήνυμα',
            icon: {
                name: 'chat',
                color: 'primary',
            },
            timestamp: Date.now(),
            title: 'Προσθήκη πεδίου',
            subtitle: 'Προστέθηκε το όχημα ΧΑ005',
            path: 'chat',
        },
    ],
}

Mock.onGet('/api/notification').reply((config) => {
    const response = NotificationDB.list
    return [200, response]
})

Mock.onPost('/api/notification/add').reply((config) => {
    const response = NotificationDB.list
    return [200, response]
})

Mock.onPost('/api/notification/delete').reply((config) => {
    let { id } = JSON.parse(config.data)
    console.log(config.data)

    const response = NotificationDB.list.filter(
        (notification) => notification.id !== id
    )
    NotificationDB.list = [...response]
    return [200, response]
})

Mock.onPost('/api/notification/delete-all').reply((config) => {
    NotificationDB.list = []
    const response = NotificationDB.list
    return [200, response]
})
