import React from 'react'
import { authRoles } from '../auth/authRoles'

const dashboardRoutes = [
    {
        path: '/biowaste',
        component: React.lazy(() => import('./BioWasteView')),
        auth: authRoles.admin,
    },
    {
        path: '/citymonthstats',
        component: React.lazy(() => import('./CityMonthStats')),
        auth: authRoles.admin,
    },
    {
        path: '/cityyearstats',
        component: React.lazy(() => import('./CityYearStats')),
        auth: authRoles.admin,
    },
    {
        path: '/dashboard',
        component: React.lazy(() => import('./Analytics')),
        auth: authRoles.admin,
    },
    {
        path: '/table',
        component: React.lazy(() => import('./DatatableView')),
        auth: authRoles.admin,
    },
    {
        path: '/waste_type_add',
        component: React.lazy(() => import('./forms/WasteTypeAddForm')),
        auth: authRoles.admin,
    },
    {
        path: '/vehicle_add',
        component: React.lazy(() => import('./forms/VehicleAddForm')),
        auth: authRoles.admin,
    },
    {
        path: '/driver_add',
        component: React.lazy(() => import('./forms/DriverAddForm')),
        auth: authRoles.admin,
    },
    {
        path: '/assistant_add',
        component: React.lazy(() => import('./forms/AssistantAddForm')),
        auth: authRoles.admin,
    },
    {
        path: '/servicearea_add',
        component: React.lazy(() => import('./forms/ServiceAreaAddForm')),
        auth: authRoles.admin,
    },
    {
        path: '/area_add',
        component: React.lazy(() => import('./forms/AreaAddForm')),
        auth: authRoles.admin,
    },
    {
        path: '/municipality_add',
        component: React.lazy(() => import('./forms/MunicipalityAddForm')),
        auth: authRoles.admin,
    },
    {
        path: '/waste_add',
        component: React.lazy(() => import('./forms/WasteAddForm')),
        auth: authRoles.admin,
    },
    {
        path: '/account',
        component: React.lazy(() => import('./AccountPage')),
        auth: authRoles.admin,
    },




    //
    {
        path: '/waste_type_list',
        component: React.lazy(() => import('../appComponents/waste_category/WasteCaregoriesList')),
        auth: authRoles.admin,
    },
    {
        path: '/vehicle_list',
        component: React.lazy(() => import('../appComponents/vehicles/VehiclesList')),
        auth: authRoles.admin,
    },
    {
        path: '/driver_list',
        component: React.lazy(() => import('../appComponents/crews/DriversList')),
        auth: authRoles.admin,
    },
    {
        path: '/assistant_list',
        component: React.lazy(() => import('../appComponents/crews/AssistantsList')),
        auth: authRoles.admin,
    },
    {
        path: '/servicearea_list',
        component: React.lazy(() => import('../appComponents/service_areas/ServiceAreasList')),
        auth: authRoles.admin,
    },
    {
        path: '/area_list',
        component: React.lazy(() => import('../appComponents/areas/AreasList')),
        auth: authRoles.admin,
    },
    {
        path: '/municipality_list',
        component: React.lazy(() => import('../appComponents/municipalities/MunicipalitiesList')),
        auth: authRoles.admin,
    },
    {
        path: '/waste_list',
        component: React.lazy(() => import('../appComponents/waste/WasteList')),
        auth: authRoles.admin,
    },
  
  

]

export default dashboardRoutes
